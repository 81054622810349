<template>
  <div class="mainBox">
    <progress-title :activeIndex.sync="activeIndex" :maxNum="maxNum" />
    <div class="progressMain">
      <div class="progressTitle">
        <img
          src="@/assets/img/homeGuardianship/pressTitle.png"
          alt=""
          class="titleIcon"
        />
        <span class="titleName">{{ pageTile }}</span>
      </div>
      <!-- 用户档案 -->
      <div class="progressFirst" v-if="activeIndex == 1">
        <el-row :gutter="30" class="proRow">
          <el-col :span="8">
            <div class="col-content">
              <div class="colLable">姓名：</div>
              <div class="colValue">{{ userInfo.userName }}</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="col-content">
              <div class="colLable">性别：</div>
              <div class="colValue">{{ userInfo.sex == 0 ? "男" : "女" }}</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="col-content">
              <div class="colLable">出生日期</div>
              <div class="colValue">{{ userInfo.bornDate }}</div>
            </div>
          </el-col>

          <!--  -->
          <el-col :span="8">
            <div class="col-content">
              <div class="colLable">电话号码：</div>
              <div class="colValue">{{ userInfo.mobile }}</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="col-content">
              <div class="colLable">身份证号码：</div>
              <div class="colValue">{{ userInfo.cardNo }}</div>
            </div>
          </el-col>

          <el-col :span="8">
            <div class="col-content">
              <div class="colLable">籍贯：</div>
              <div class="colValue">{{ userInfo.nativePlace }}</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="col-content">
              <div class="colLable">家庭电话：</div>
              <div class="colValue">{{ userInfo.homeTel }}</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="col-content">
              <div class="colLable">地址：</div>
              <div class="colValue">{{ userInfo.address }}</div>
            </div>
          </el-col>

          <el-col :span="8">
            <div class="col-content">
              <div class="colLable">民族：</div>
              <div class="colValue">{{ userInfo.nationTxt }}</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="col-content">
              <div class="colLable">文化程度：</div>
              <div class="colValue">{{ userInfo.diploma }}</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="col-content">
              <div class="colLable">单位：</div>
              <div class="colValue">{{ userInfo.company }}</div>
            </div>
          </el-col>

          <el-col :span="8">
            <div class="col-content">
              <div class="colLable">收入：</div>
              <div class="colValue">{{ userInfo.revenue }}</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="col-content">
              <div class="colLable">职业状态：</div>
              <div class="colValue">{{ userInfo.occupationStatusTxt }}</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="col-content">
              <div class="colLable">婚姻：</div>
              <div class="colValue">{{ userInfo.marriageTypeTxt }}</div>
            </div>
          </el-col>

          <el-col :span="8">
            <div class="col-content">
              <div class="colLable">血型：</div>
              <div class="colValue">{{ userInfo.bloodTypeTxt }}</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="col-content">
              <div class="colLable">身高：</div>
              <div class="colValue">{{ userInfo.height }}</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="col-content">
              <div class="colLable">体重：</div>
              <div class="colValue">{{ userInfo.weight }}</div>
            </div>
          </el-col>

          <el-col :span="8">
            <div class="col-content">
              <div class="colLable">BMI：</div>
              <div class="colValue">{{ userInfo.bmi }}</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="col-content">
              <div class="colLable">用户属性：</div>
              <div class="colValue">{{ userInfo.userPropertyTxt }}</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="col-content">
              <div class="colLable">能力等级：</div>
              <div class="colValue">{{ userInfo.abilityLevelTxt }}</div>
            </div>
          </el-col>

          <el-col :span="8">
            <div class="col-content">
              <div class="colLable">健康状况：</div>
              <div class="colValue">{{ userInfo.healthStatusTxt }}</div>
            </div>
          </el-col>

          <el-col :span="8">
            <div class="col-content">
              <div class="colLable">居住状态：</div>
              <div class="colValue">{{ userInfo.liveStatusTxt }}</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="col-content">
              <div class="colLable">客户类型：</div>
              <div class="colValue">{{ userInfo.userTypeTxt }}</div>
            </div>
          </el-col>

          <el-col :span="8">
            <div class="col-content">
              <div class="colLable">备注：</div>
              <div class="colValue">{{ userInfo.remark }}</div>
            </div>
          </el-col>
        </el-row>
      </div>
      <!-- 签订合同 -->
      <div class="progressFirst" v-if="activeIndex == 2">
        <div class="htBox" v-for="(item, index) in typeList1" :key="index">
          <el-image
            class="htImg"
            :src="item.url"
            :preview-src-list="typeListImg1"
          >
          </el-image>
        </div>
      </div>
      <!-- 定制方案 -->
      <div class="progressFirst" v-if="activeIndex == 3">
        <div class="htBox" v-for="(item, index) in typeList2" :key="index">
          <el-image
            class="htImg"
            :src="item.url"
            :preview-src-list="typeListImg2"
          >
          </el-image>
        </div>
      </div>
      <!-- 安装 -->
      <div class="progressFirst" v-if="activeIndex == 4">
        <div class="htBox" v-for="(item, index) in typeList3" :key="index">
          <el-image
            class="htImg"
            :src="item.url"
            :preview-src-list="typeListImg3"
          >
          </el-image>
        </div>
      </div>
      <!-- 验收 -->
      <div class="progressFirst" v-if="activeIndex == 5">
        <div class="htBox" v-for="(item, index) in typeList5" :key="index">
          <el-image
            class="htImg"
            :src="item.url"
            :preview-src-list="typeListImg5"
          >
          </el-image>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import progressTitle from "../../components/progressTitle.vue";
import { GetBizDoc, GetBizDocAnnex } from "../../api/homeGuardianship";

export default {
  components: {
    progressTitle,
  },
  data() {
    return {
      activeIndex: 1,
      pageTile: "基本信息",
      userInfo: {},
      typeList1: [], //服务合同
      typeList2: [], //定制方案
      typeList3: [], //设备清单
      typeList5: [], //验收单
      typeListImg1: [],
      typeListImg2: [],
      typeListImg3: [],
      typeListImg5: [],
      maxNum: 0,
    };
  },
  created() {
    this.getBizDoc();
    this.getBizDocAnnex();
  },

  mounted: function () {},
  destroyed: function () {},
  computed: {},
  watch: {
    activeIndex: {
      handler(n) {
        if (n == 1) {
          this.pageTile = "基本信息";
        } else if (n == 2) {
          this.pageTile = "服务合同";
        } else if (n == 3) {
          this.pageTile = "定制方案";
        } else if (n == 4) {
          this.pageTile = "安装实施";
        } else if (n == 5) {
          this.pageTile = "验收清单";
        }
      },
    },
  },

  methods: {
    getBizDoc() {
      GetBizDoc().then((res) => {
        console.log(res);
        let data = res.result;
        this.userInfo = data.userInfo;
        this.activeIndex = data.stageType + 1;
        this.maxNum = data.stageType + 1;
      });
    },
    getBizDocAnnex() {
      GetBizDocAnnex().then((res) => {
        let data = res.result;
        this.typeList1 = data.type1;
        this.typeList2 = data.type2;
        this.typeList3 = data.type3;
        this.typeList5 = data.type5;
        this.typeListImg1 = data.type1.map((item) => {
          return item.url;
        });
        this.typeListImg2 = data.type2.map((item) => {
          return item.url;
        });
        this.typeListImg3 = data.type3.map((item) => {
          return item.url;
        });
        this.typeListImg5 = data.type5.map((item) => {
          return item.url;
        });
      });
    },
  },
};
</script>
<style lang="less" scoped>
.progressMain {
  width: 100%;
  min-height: 584px;
  background: #ffffff;
  border-radius: 20px 20px 20px 20px;
  padding: 20px 30px;
}
.titleIcon {
  width: 14px;
  height: 14px;
  margin-right: 5px;
}
.titleName {
  font-size: 14px;
  font-weight: 400;
  color: #2f69e6;
}
.progressTitle {
  display: flex;
  align-items: center;
}
.col-content {
  display: flex;
  align-items: center;
}
.progressFirst {
  margin-top: 20px;
}
.colLable {
  font-size: 12px;
  font-weight: 400;
  color: #2f69e6;
  flex: 1;
  text-align: right;
}
.colValue {
  width: 292px;
  min-height: 38px;
  background: rgba(47, 105, 230, 0.05);
  border-radius: 5px 5px 5px 5px;
  padding: 13px 15px;
  font-size: 12px;
  font-weight: 400;
  color: #333333;
}
.proRow {
  margin-bottom: 15px;
  display: flex;
  flex-wrap: wrap;
  .el-col {
    margin: 10px 0;
  }
}
.htBox {
  width: 183px;
  height: 245px;
  background: rgba(47, 105, 230, 0.07);
  border-radius: 5px 5px 5px 5px;
  padding: 6px 7px;
}
.htImg {
  width: 100%;
  height: 100%;
}
</style>
