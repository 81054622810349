<template>
  <div class="progressHead">
    <div class="progressNav">
      <div
        class="progressNav-item"
        v-for="(item, index) in progressNavList"
        :key="index"
      >
        <div
          class="progressNav-item-title"
          :class="index < activeIndex ? 'itemActive' : ''"
          @click="changIndex(index + 1)"
        >
          {{ item }}
        </div>
      </div>
      <img
        src="@/assets/img/homeGuardianship/01.png"
        alt=""
        class="proImg"
        v-show="activeIndex == 1"
      />
      <img
        src="@/assets/img/homeGuardianship/02.png"
        alt=""
        class="proImg"
        v-show="activeIndex == 2"
      />
      <img
        src="@/assets/img/homeGuardianship/03.png"
        alt=""
        class="proImg"
        v-show="activeIndex == 3"
      />
      <img
        src="@/assets/img/homeGuardianship/04.png"
        alt=""
        class="proImg"
        v-show="activeIndex == 4"
      />
      <img
        src="@/assets/img/homeGuardianship/05.png"
        alt=""
        class="proImg"
        v-show="activeIndex == 5"
      />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    activeIndex: Number,
    maxNum: Number,
  },
  data() {
    return {
      progressNavList: ["用户档案", "签订合同", "定制方案", "安装实施", "验收"],
    };
  },
  created() {},
  computed: {},
  watch: {},
  methods: {
    changIndex(index) {
      if (index > this.maxNum) {
        return false;
      }

      this.$emit("update:activeIndex", index);
    },
  },
};
</script>
<style lang="less" scoped>
.progressHead {
  display: flex;
  justify-content: center;
}
.progressNav {
  width: 712px;
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.itemActive {
  font-size: 16px;
  font-weight: 500;
  color: #2f69e6;
}
.progressNav-item-title {
  font-size: 16px;
  font-weight: 500;
  color: #96b3f3;
  cursor: pointer;
}

.itemActive {
  color: #2f69e6;
}
.proImg {
  width: 102%;
  margin-top: 20px;
}
</style>
